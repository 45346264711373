import React from 'react';
import { useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { Home, Inbox, FileText, Radio, BarChart2, Users, Wallet, Bell, Settings, HelpCircle } from 'lucide-react';
import logo from "../Contact Page/images/forSidebar/logo.png";
import settings from "../Contact Page/images/forSidebar/settings.svg";
import green from "../Contact Page/images/forSidebar/green.svg";
import house from "../Contact Page/images/forSidebar/house.svg";
import card from "../Contact Page/images/forSidebar/card.svg";
import bell from "../Contact Page/images/forSidebar/bell.svg";
import wallet from "../Contact Page/images/forSidebar/wallet.svg";
import info from "../Contact Page/images/forSidebar/info.svg";
import inbox from "../Contact Page/images/forSidebar/inbox.svg";
import graph from "../Contact Page/images/forSidebar/graph.svg";
const menuItems = [
  { icon: house, label: 'Home', path: '/home' },
  { icon: inbox, label: 'Inbox', path: '/inbox' },
  { icon: card, label: 'Templates', path: '/templates' },
  { icon: house, label: 'Broadcast', path: '/broadcast' },
  { icon: graph, label: 'Analytics', path: '/analytics' },
  { icon: green, label: 'Contacts', path: '/contacts' },
  { icon: wallet, label: 'My Wallet', path: '/wallet' },
  { icon: bell, label: 'Subscription', path: '/subscription' },
  { icon: settings, label: 'Settings', path: '/settings' },
  { icon: info, label: 'Help', path: '/help' },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [bgColor, setBgColor] = useState('#001a2c');

  const handleNavigation = (path, label) => {
    navigate(path);
    setBgColor(getRandomColor());
  };

  const getRandomColor = () => {
    const colors = ['#001a2c'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div className="w-64 h-screen text-white p-4 flex flex-col" style={{ backgroundColor: bgColor, fontFamily:"poppins-reg" }}>
    <div className="flex flex-row items-center mb-8" style={{margin:"20px auto 30px 20px"}}>
  <div>
    <img src={logo} alt="logo" className="h-9 w-20" /> 
  </div>
  <h1 className="text-xl font-bold ml-2">Reybot</h1> 
</div>


      <nav className="flex-grow">
  <ul>
    {menuItems.map((item) => (
      <li key={item.label} className="mb-2">
        <button
          onClick={() => handleNavigation(item.path, item.label)}
          className={`flex items-center w-full p-2 rounded transition-colors ${
            location.pathname === item.path ? 'text-green-600' : 'text-gray-300'
          }`}
        >
          <img src={item.icon} alt={item.label} className="mr-3 h-5 w-5" />
          {item.label}
        </button>
      </li>
    ))}
  </ul>
</nav>


      <button className="mt-auto bg-green-500 text-white py-2 px-4 rounded-full hover:bg-green-600 transition-colors">
        Premium
      </button>
      <div className="flex-grow p-8">
        <Outlet/>
      </div>
    </div>
  );
};

export default Sidebar;