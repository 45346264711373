import "./navbar.css";
import logo from "../../images/forNavbar/logo.png";
import Lnk from "./Link";
import moon from "../../images/forNavbar/moon.svg";
import Dropdown from "./Dropdown";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Navbar() {
  const { token } = useSelector((state) => state.auth);
  return (
    <div className="navbar">
      <img className="logo" src={logo} alt="logo" />
      <div className="links">
        <Lnk path="" name={"Home"} />
        <Lnk path="#features" name={"Features"} />
        <Lnk path="#solutions" name={"Solutions"} />
        <Lnk path="#pricing" name={"Pricing"} />
      </div>
      <img className="mode" src={moon} alt="mode" />
      <Dropdown />
      {token ? (
        <Link to="/home">
          <p className="greenbutton" style={{ color: "white" }}>
            Dashboard
          </p>
        </Link>
      ) : (
        <>
          <Link
            to="/signup"
            className="greenbutton"
            style={{ color: "#0DD171" }}
          >
            Sign Up
          </Link>
          <Link
            to="/login"
            className="greenbutton"
            style={{ color: "#0DD171" }}
          >
            Sign In
          </Link>
        </>
      )}

      {/* if(token){

            }
            <p className="greenbutton" style={{color:"white"}}>Dashboard</p>
            <Link  to="/signup" className="greenbutton" style={{color:"#0DD171"}}>Sign Up</Link> */}
    </div>
  );
}

export default Navbar;
