"use client";
import React, { useContext, useState } from "react";
import axios from "axios";
import SessionContext from "../context/SessionContext";

const WhatsAppMessageSender = () => {
  const [recipientNumber, setRecipientNumber] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [status, setStatus] = useState("");

  const { accessToken, phoneId } = useContext(SessionContext);

  const sendMessage = async () => {
    // const accessToken =
    // "EAALlzWygEW0BOznmpxZAwhbDZAF9iHlFOvcIluX7oPp0aRgBAMlivTnuR7GVUp2j3wYkaDt09lSrKl7aE2ZBFZAkP4nZAkOuXw08YOdGEMWHKsz0w43hZBDXY4qRaMsStZBinZCETTUFXJuktatppMwzw90ZBUQ6Caa4ZA2h6LVXuZAVpLUGirVOPADurY98eAwLkjNkyZBiaKHGeGaPUV14ZBrZBl52pNA2D5K2gP05KUQNZA0J5FBZBxNp4abJiV1ATcv0dMXo1vYgohp3zkUZCtaXuKMhrtaI3nimuCdhR"; // Replace with your actual access token
    // const phoneNumberId = "429779076880796";
    const phoneNumberId = phoneId;

    try {
      const response = await axios.post(
        `https://graph.facebook.com/v20.0/${phoneNumberId}/messages`,
        {
          messaging_product: "whatsapp",
          to: recipientNumber,
          type: "text",
          text: {
            body: messageBody,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      setStatus("Message sent successfully!");
      console.log("Message sent successfully:", response.data);
    } catch (error) {
      setStatus("Error sending message");
      console.error(
        "Error sending message:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage();
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-2">
      <h1 className="text-3xl font-bold mb-4">Send a WhatsApp Message</h1>
      <form
        onSubmit={handleSubmit}
        className="bg-gray-100 p-6 rounded-lg shadow-md w-96"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="recipient"
          >
            Recipient's Phone Number
          </label>
          <input
            type="text"
            id="recipient"
            value={recipientNumber}
            onChange={(e) => setRecipientNumber(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter phone number"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="message"
          >
            Message
          </label>
          <textarea
            id="message"
            value={messageBody}
            onChange={(e) => setMessageBody(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter your message"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Send Message
        </button>
      </form>
      {status && <p className="mt-4 text-green-500">{status}</p>}
    </div>
  );
};

export default WhatsAppMessageSender;
