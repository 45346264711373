import React, { useState, useEffect, useContext } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import {
  updateCompanyAPI,
  getProfileAPI,
  deleteAccountAPI,
} from "../../APIServices/usersAPI";
import { FaBuilding, FaEnvelope, FaPhoneAlt, FaTrashAlt } from "react-icons/fa";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import SessionContext from "../../context/SessionContext";

export const Company = () => {
  const navigate = useNavigate();
  const {setSessEmail} = useContext(SessionContext);
  const [companyData, setCompanyData] = useState({
    companyName: "",
    sector: "",
    phoneNo: "",
    businessEmail: "",
    billingAddress: "",
    zip: "",
    city: "",
    country: "",
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["fetch-profile"],
    queryFn: getProfileAPI,
  });

  useEffect(() => {
    if (data?.userDetails?.company) {
      setCompanyData({
        companyName: data?.userDetails?.company?.companyName || "",
        sector: data?.userDetails?.company?.sector || "",
        phoneNo: data?.userDetails?.company?.phoneNo || "",
        businessEmail: data?.userDetails?.company?.businessEmail || "",
        billingAddress: data?.userDetails?.company?.billingAddress || "",
        zip: data?.userDetails?.company?.zip || "",
        city: data?.userDetails?.company?.city || "",
        country: data?.userDetails?.company?.country || "",
      });
    }
  }, [data]);

  const updateCompanyMutation = useMutation({
    mutationFn: updateCompanyAPI,
    onSuccess: (response) => {
      toast.success("Company details updated successfully");
    },
    onError: (error) => {
      toast.error("Failed to update company details");
      console.error("Company update error:", error);
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateCompanyMutation.mutate(companyData);
  };

  const deleteAccountMutation = useMutation({
    mutationFn: deleteAccountAPI,
    onSuccess: (response) => {
      toast.success("Account deleted successfully");
    },
    onError: (error) => {
      toast.error("Failed to delete account");
      console.error("Delete account error:", error);
    },
  });

  const cancelDeleteAccount = () => {
    console.log("cancel delete account");
    setShowDeleteModal(false);
  };

  const handleDeleteAccount = () => {
    deleteAccountMutation.mutate();
    localStorage.removeItem("email");
    setSessEmail(null);
    localStorage.removeItem("token");
    document.cookie = "token=; Max-Age=0";
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="relative p-8 bg-white shadow-lg rounded-lg max-w-4xl mx-auto">
      <div className="text-lg font-semibold mb-6 text-gray-700">Company</div>
      <div className="text-sm text-gray-500 mb-4">
        You can change your company information here.
      </div>

      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Company Name
            </label>
            <div className="relative mt-1">
              <FaBuilding className="absolute left-3 top-3 text-gray-500" />
              <input
                type="text"
                name="companyName"
                value={companyData.companyName}
                onChange={handleInputChange}
                className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 py-2"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Sector
            </label>
            <input
              type="text"
              name="sector"
              value={companyData.sector}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 py-2"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              WhatsApp Number
            </label>
            <div className="flex mt-1 items-center">
              <div className="w-24">
                <Select
                  className="w-full z-10"
                  options={[
                    { label: "UK", value: "uk" },
                    { label: "US", value: "us" },
                  ]}
                  defaultValue={{ label: "UK", value: "uk" }}
                />
              </div>
              <input
                type="text"
                name="phoneNo"
                value={companyData.phoneNo}
                onChange={handleInputChange}
                className="block w-full pl-4 rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 py-2 ml-3"
                placeholder="Enter your WhatsApp number"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Business Email
            </label>
            <div className="relative mt-1">
              <FaEnvelope className="absolute left-3 top-3 text-gray-500" />
              <input
                type="email"
                name="businessEmail"
                value={companyData.businessEmail}
                onChange={handleInputChange}
                className="block w-full pl-10 rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 py-2"
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Billing Address
            </label>
            <input
              type="text"
              name="billingAddress"
              value={companyData.billingAddress}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 py-2"
              placeholder="Address line 1"
            />
            <input
              type="text"
              className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 py-2"
              placeholder="Address line 2"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Zip
              </label>
              <input
                type="text"
                name="zip"
                value={companyData.zip}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 py-2"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                City
              </label>
              <input
                type="text"
                name="city"
                value={companyData.city}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 py-2"
              />
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Country
          </label>
          <input
            type="text"
            name="country"
            value={companyData.country}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 py-2"
          />
        </div>

        <div className="flex justify-between items-center mt-6">
          <button
            type="button"
            onClick={() => setShowDeleteModal(true)}
            className="text-sm text-red-600 hover:text-red-800 flex items-center"
          >
            <FaTrashAlt className="mr-2" />
            Delete this account
          </button>

          <button
            type="submit"
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700"
          >
            Save
          </button>
        </div>
      </form>
      {showDeleteModal && (
        <div className="fixed inset-0 bg-green-600 bg-opacity-70 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-semibold text-center mb-4 text-red-700">
              Are you sure you want to delete your account?
            </h2>
            <p className="text-center text-gray-600 mb-6">
              Once you delete your account, there is no going back. Please be
              certain.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={handleDeleteAccount}
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
              >
                Yes
              </button>
              <button
                onClick={cancelDeleteAccount}
                className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
