import Table from "./components/table/Table";
import Topbar from "./components/topbar/Topbar";
import "./contact.css";
import excel from "./images/excel.png";
import download from "./images/download.svg";
import AddContact from "./components/addcontact/AddContact";
import { useState } from "react";

function Contact(){
    const [isAdd, setAdd] = useState(false);

    return (
        <>
            <Topbar />
            <div className="contact">
                <div className="main">
                    {isAdd && (
                        <AddContact func={setAdd}/>
                    )}
                    {!isAdd && (
                        <>
                            <div className="contactpt1">
                                <div className="lft">
                                    <p>Contacts</p>
                                    <p>The contact list stores the numbers you have interacted with. Additionally, you<br></br>have the option to manually export or import contacts.</p>
                                </div>
                                <p className="btn" onClick={() => setAdd(true)}>+ Add Contact</p>
                            </div>
                            <div className="export">
                                <img src={excel} alt="excel" />
                                <p>Import from excel sheet</p>
                                <img src={download} alt="download" />
                            </div>
                            <Table />
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Contact;