import React, { useState, useEffect } from "react";
import axios from "axios";
import ContactList from "./ContactList";
import PhoneImage from "../../assests/Phone — FixedHeight.png";
import { BASE_URL } from "../../utils/endPoint/baseUrl";
import { useContext } from "react";
// import SessionContext from "../../context/SessionContext";


import { 
  getMetaStorageValues
} from '../../context/getAccessTokenData';

export const Modal = ({ closeModal, onSubmit, defaultValue }) => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const formatDateToMMDDYYYY = (date) => {
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const date = new Date();
  const formattedDate = formatDateToMMDDYYYY(date);
  const [variableValues, setVariableValues] = useState({});

  const [formState, setFormState] = useState(
    defaultValue || {
      template: "",
      title: "",
      selectedContacts: [],
      isSchedule: false,
      scheduledDate: new Date(),
      isSendNow: false,
    }
  );

  const [errors, setErrors] = useState("");
  // const { phoneId, accessToken } = useContext(SessionContext);

  const { 
    accessToken,
    whatsappId,
    phoneId
  } = getMetaStorageValues();

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(
          `https://graph.facebook.com/v20.0/${whatsappId}/message_templates?status=approved`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setTemplates(response.data.data);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };

    fetchTemplates();
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "title") {
      const selectedTemplate = templates.find((t) => t.name === e.target.value);
      setSelectedTemplate(selectedTemplate);
      setVariableValues({});
    }
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleVariableChange = (index, value) => {
    setVariableValues({ ...variableValues, [index]: value });
  };

  const handleContactSelection = (selectedContacts) => {
    setFormState({ ...formState, selectedContacts });
  };

  const handleScheduleChange = (isSchedule, scheduledDate) => {
    setFormState({ ...formState, isSchedule, scheduledDate });
  };

  const handleSendNowChange = (isSendNow) => {
    setFormState({ ...formState, isSendNow, scheduledDate: new Date() });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    console.log("\n\n" + JSON.stringify(formState) + "\n\n");

    try {
      for (const contact of formState.selectedContacts) {
        const messageBody = {
          messaging_product: "whatsapp",
          to: contact.contactNo,
          type: "template",
          template: {
            name: formState.title,
            language: {
              code: "en",
            },
            components: [
              {
                type: "body",
                parameters: Object.entries(variableValues).map(
                  ([_, value]) => ({
                    type: "text",
                    text: value,
                  })
                ),
              },
            ],
          },
        };

        await axios.post(
          `https://graph.facebook.com/v20.0/${phoneId}/messages`,
          messageBody,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
      }

      const response = await axios.post(`${BASE_URL}/broadcasts`, formState, {withCredentials: true});
      if (response.status === 201) {
        closeModal();
        onSubmit(formState);
      }
    } catch (error) {
      console.error("Error sending messages:", error);
      setErrors("An error occurred while sending the messages.");
    }
  };
  const validateForm = () => {
    if (
      formState.template &&
      formState.title &&
      formState.selectedContacts.length > 0 &&
      Object.keys(variableValues).length ===
        (
          selectedTemplate?.components
            .find((c) => c.type === "BODY")
            ?.text.match(/{{[^}]+}}/g) || []
        ).length
    ) {
      setErrors("");
      return true;
    } else {
      setErrors("All fields including variable values are required.");
      return false;
    }
  };

  // Fetch templates when the component mounts
  // useEffect(() => {
  //   const fetchTemplates = async () => {
  //     try {
  //       // TODO /V1 is not there avani
  //       const response = await axios.get(`${BASE_URL}/templates`, {withCredentials: true});
  //       setTemplates(response.data.data);
  //     } catch (error) {
  //       console.error("Error fetching templates:", error);
  //     }
  //   };

  //   fetchTemplates();
  // }, []);

  const broadcastMsgs = () => {
    if (formState.isSendNow && selectedTemplate !== null) {
      fetch(`${BASE_URL}/v1/broadcastMessages`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          template: selectedTemplate,
          sessEmail: localStorage.getItem("email"),
        }),
      })
        .then((res1) => res1.json())
        .then((res2) => {
          if (!res2.success) {
            console.log("error");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div
      className="modal-container"
      onClick={(e) => {
        if (e.target.className === "modal-container") closeModal();
      }}
    >
      <div className="h-[90vh] w-[60vw] relative inset-0 -z-10 items-center px-5 pt-4 pb-12 left-36 bg-gray-100 rounded-xl overflow-hidden py-4">
        <div className="w-full h-12 text-lg font-semibold flex items-center px-[2%] border-gray-500 border-b-2">
          <h2>Create New Broadcast</h2>
        </div>

        <div className="w-full h-full rounded-xl overflow-hidden flex justify-center items-center mt-4">
          <div className="w-[70%] h-[100%] rounded-xl overflow-y-scroll">
            <div className="h-[60vh] w-[80%]">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="template">Broadcast Name</label>
                  <input
                    className="w-[50%]"
                    name="template"
                    onChange={handleChange}
                    value={formState.template}
                    required
                  />
                </div>

                <div className="form-group flex justify-center gap-2">
                  <label htmlFor="title">Select Template Message</label>
                  <select
                    name="title"
                    value={formState.title}
                    className="w-[50%]"
                    onChange={(e) => {
                      handleChange(e);
                      const selectMyTemp = templates.find(
                        (template) => template.template === e.target.value
                      );
                      setSelectedTemplate(selectMyTemp);
                    }}
                    required
                  >
                    <option value="">Select a template</option>
                    {templates.map((template, ind) => (
                      <option key={ind} value={template.name}>
                        {template.name}
                      </option>
                    ))}
                  </select>
                </div>

                {selectedTemplate && (
                  <div className="form-group">
                    <h3 className="mb-4">Add Variable Values</h3>
                    {(
                      selectedTemplate.components
                        .find((c) => c.type === "BODY")
                        ?.text.match(/{{[^}]+}}/g) || []
                    ).map((variable, index) => (
                      <div key={index} className="mb-2">
                        <label htmlFor={`variable-${index}`} className="mr-4">
                          {variable}
                        </label>
                        <input
                          id={`variable-${index}`}
                          className="w-[50%]"
                          onChange={(e) =>
                            handleVariableChange(index, e.target.value)
                          }
                          value={variableValues[index] || ""}
                          required
                        />
                      </div>
                    ))}
                  </div>
                )}

                <ContactList
                  onContactChange={handleContactSelection}
                  onScheduleChange={handleScheduleChange}
                  onSendNowChange={handleSendNowChange}
                  selectedContacts={formState.selectedContacts}
                />

                {errors && (
                  <div className="error text-red-500 mb-2">{errors}</div>
                )}

                <button
                  type="submit"
                  className="btn bg-green-500 px-5 py-3 rounded-lg mb-10"
                  onClick={broadcastMsgs}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="w-[30%] h-full">
            <img
              src={PhoneImage}
              alt="phone"
              className="relative mx-auto h-[95%] w-full z-10"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
