import "./table.css";
import edit from "../../images/forTable/edit.svg";
import bin from "../../images/forTable/bin.svg";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils/endPoint/baseUrl";
// import data from "../../data";

function Tuple(props){
    const {Name, Phno, Email, YON, contactID} = props;
    let clr;
    if (YON === "YES"){clr = "#319A29"}
    else{clr = "#EB4335"}

    const deleteContact = () =>{
        fetch(`${BASE_URL}/v1/contacts`, {
            method: "DELETE",
            headers: {'Content-Type':'application/json'},
            body:JSON.stringify({contactID:contactID}),
            credentials: "include"
        })
        .then(res => res.json())
        .then(res2 => {
            if (res2.message==="done"){
                window.location.reload();
            }
            else{console.log(res2.message)}
        })
        .catch(err =>console.log(err));
    };
    
    return (
        <div className="tuple">
            <p>{Name}</p>
            <p>{Phno}</p>
            <p>{Email}</p>
            <p style={{marginLeft:"9.858vw", textAlign:"center", color:clr}}>{YON}</p>
            <div className="actions">
                <img src={edit} alt="edit" className="edit" />
                <img src={bin} onClick={deleteContact} alt="delete" className="bin" />
            </div>
        </div>
    );
}

function Table(){
    const [data, setData] = useState([]);

    useEffect(() =>{
        fetch(`${BASE_URL}/v1/contacts`, {
            method:"GET",
            headers: {'Content-Type':'application/json'},
            credentials: "include"
        })
        .then(res =>res.json())
        .then(ans=>{
            if (ans.arr){
                setData(ans.arr);
            }
        })
        .catch(err =>console.log(err));

    }, []);

    const renderTuples = data.map((x, ind) =>{

        return (<Tuple Name={x.name} Phno={x.contactNo} Email={x.businessEmail} YON={x.subscribed?"YES":"NO"} contactID={x._id} key={ind} />);

    })

    return (
        <div className="table">
            <div className="attr">
                <p>Name</p>
                <p>Phone Number</p>
                <p>Email Id</p>
                <p style={{marginLeft:"9.858vw", textAlign:"center"}}>Subscribed</p>
                <p style={{marginLeft:"3.5vw"}}>Action</p>
            </div>
            {renderTuples}
        </div>
    );
}

export default Table;