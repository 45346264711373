import React from 'react';
import { Briefcase, Calendar, TrendingUp } from 'lucide-react';
import { MessageCircle, Edit, MonitorPlay } from 'lucide-react';
import hello from "../assests/pana.png";
import Topbar from '../Contact Page/components/topbar/Topbar';
import "./clienthome.css";
import { Link } from "react-router-dom";
import whatsapp from "../assests/whatsapp.png";
import api from "../assests/api.png";
import filter from "../assests/filter.png";
import faq from "../assests/faq.png";
import v1 from "../assests/v1.png";
import v2 from "../assests/v2.png";
import v3 from "../assests/v3.png";


const ClientHome = () => {
  const cards = [
    {img:whatsapp, title:"Create campaigns in Whatsapp"},
    {img:api, title:"API Chatbot"},
    {img:filter, title:"Lead Generation"},
    {img:faq, title:"FAQ"}
  ];

  const features = [
    {img:v1, title:"Templates"},
    {img:v2, title:"Appointments"},
    {img:v3, title:"Grow your business"}
  ];

  const renderCards = cards.map((x, ind) =>{
    return (
      <div className='func' key={ind}>
        <img src={x.img} alt='feature' />
        <p>{x.title}</p>
      </div>
    );
  });

  const renderFeatures = features.map((x, ind) =>{
    return (
      <div className='feat' key={ind}>
        <div><p>{x.title}</p><p>Create</p></div>
        <img src={x.img} alt='vector' />
      </div>
    );
  });

  return (
    <>
      <Topbar />
      <div className='clienthome'>
        <div className='banner'>
          <div>
            <p>Welcome back!</p>
            <p>Your free trial expires in 15 days</p>
            <Link className='upg'>Upgrade Plan</Link>
          </div>

          <img src={hello} alt='banner' />
        </div>

        <p className='header1'>Discover <span>How To?</span></p>
        <div className='funcs'>
          {renderCards}
        </div>

        <p className='header1'>Discover <span>How To?</span></p>
        <div className='funcs'>
          {renderFeatures}
        </div>
      </div>
    </>
  );
};

export default ClientHome;