import React, { useState, useContext, useEffect } from "react";
import search from "../../images/forTopbar/search.svg";
import greenbell from "../../images/forTopbar/greenbell.svg";
import acc from "../../images/forTopbar/acc.png";
import arrow from "../../images/forTopbar/arrowdown.svg";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast"; // For toast notifications
import {
  getProfileAPI,
  logoutAPI,
  getAccessTokenAPI,
} from "../../../APIServices/usersAPI"; // Import the logout API
import SessionContext from "../../../context/SessionContext";
import "./topbar.css";

function Topbar() {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["fetch-profile"],
    queryFn: getProfileAPI,
  });

  const { setSessEmail, setAccessToken } = useContext(SessionContext);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMetaAccessToken = async () => {
      try {
        const data = await getAccessTokenAPI();
        console.log("Access token data in topbar", data);

        setAccessToken(data.access_token);
        localStorage.setItem("meta_access_token", data.access_token);
        localStorage.setItem("meta_phone_id", data.phone_id);
        localStorage.setItem("meta_whatsapp_id", data.waba_id);
        localStorage.setItem("meta_app_id", data.app_id);
      } catch (error) {
        console.error("Failed to fetch meta access token:", error);
      }
    };
    fetchMetaAccessToken();
  }, []);

  const logoutMutation = useMutation({
    mutationFn: logoutAPI,
    onSuccess: () => {
      localStorage.removeItem("token");
      document.cookie = "token=; Max-Age=0";
      toast.success("User logged out successfully");
      setSessEmail(null);
      localStorage.removeItem("email");
      setShowLogoutModal(false);
      navigate("/");
      window.location.reload();
    },
    onError: (error) => {
      toast.error("Failed to log out");
      console.error("Logout error:", error);
    },
  });

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const openLogoutModal = () => {
    setDropdownVisible(false);
    setShowLogoutModal(true);
  };

  const handleLogout = () => {
    logoutMutation.mutate();
  };

  const cancelLogout = () => {
    setShowLogoutModal(false);
  };

  return (
    <div className="relative">
      <div className="topbar">
        <div className="srch">
          <img src={search} alt="search" />
          <input
            type="text"
            name="search"
            placeholder="Search for anything..."
            autoComplete="off"
          />
        </div>

        <Link to="" className="notif">
          <img src={greenbell} alt="Notifications" />
        </Link>

        <div className="account" onClick={toggleDropdown}>
          <img
            src={data?.userDetails?.image}
            alt="profile"
            className="profile"
          />
          <div className="tempdiv">
            <p>Hi, there</p>
            <p>{data?.userDetails?.firstName}</p>
          </div>
          <img src={arrow} alt="arrow" className="arrow" />

          {dropdownVisible && (
            <div className="absolute right-10 mt-10 w-48 bg-white rounded-md shadow-lg z-10">
              <div className="py-2">
                <button
                  onClick={openLogoutModal}
                  className="block w-full px-4 py-2 text-left text-green-600 hover:bg-gray-100 hover:text-green-800"
                >
                  Logout
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {showLogoutModal && (
        <div className="fixed inset-0 bg-green-600 bg-opacity-70 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-semibold text-center mb-4 text-green-700">
              Are you sure you want to logout?
            </h2>
            <p className="text-center text-gray-600 mb-6">
              You will be logged out from Reybot.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={handleLogout}
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
              >
                Yes
              </button>
              <button
                onClick={cancelLogout}
                className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Topbar;
