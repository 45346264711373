import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBanner(true); 
    }
  }, []);

  const acceptCookies = () => {

    localStorage.setItem('cookieConsent', 'accepted');
  
    setShowBanner(false); 
  };

  if (!showBanner) return null; 

  return (
    <div className="fixed bottom-0 left-0 w-full bg-green-200 text-gray-800 py-4 px-6 flex justify-between items-center z-50">
      <p className="text-sm">
        We use cookies to ensure you get the best experience on our website. By using our site, you agree to our
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-green-700 underline ml-1">
          Privacy Policy
        </a>
        .
      </p>
      <button
        onClick={acceptCookies}
        className="bg-green-500 text-white text-sm py-2 px-4 rounded-lg hover:bg-green-600 transition-colors"
      >
        Accept All Cookies
      </button>
    </div>
  );
};

export default CookieConsentBanner;
