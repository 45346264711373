import React, { useState } from 'react';
import { ChevronDown, Bell, User } from 'lucide-react';
import { useSelector } from 'react-redux';
import Topbar from '../Contact Page/components/topbar/Topbar';
import { useQuery } from '@tanstack/react-query';
import { getProfileAPI } from '../APIServices/usersAPI';
import { Personal } from './Settings/Personal';
import { Company } from './Settings/Company';

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState('Personal info');
  const {data,isLoading,isError,error}=useQuery({
    queryKey:["fetch-profile"],
    queryFn:getProfileAPI,
})
  return (

    <div className="min-h-screen bg-gray-100 flex flex-col" style={{fontFamily: "poppins-reg"}}>
      <Topbar/>

      <div className="flex-grow bg-gray-100 py-8 ">
        <div className="w-[100] h-full mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
  
          <div className="bg-emerald-400 h-32 relative">
            <div className="absolute -bottom-10 left-6">
              <div className="bg-gray-200 rounded-full h-20 w-20 flex items-center justify-center border-4 border-white">
                <User src={data?.userDetails?.image} size={40} className="text-gray-600" />
              </div>
            </div>
          </div>


          <div className="p-6 pt-16">
            <div className='text-2xl font-semibold text-black '>
              {data?.userDetails?.firstName} {data?.userDetails?.lastName}
              </div>


            <div className="flex justify-between items-center mb-6">
              {/* <h2 className="text-2xl font-semibold">{data?.userDetails?.firstName}</h2> */}
              <span className="bg-green-100 text-green-800 px-2 py-1 rounded text-sm">{data?.userDetails?.subscriptionPlan}</span>
            </div>

            {/* Tabs */}
            <div className="flex border-b mb-6">
              {['Personal info', 'Company', 'Notifications'].map((tab) => (
                <button
                  key={tab}
                  className={`mr-6 pb-2 ${
                    activeTab === tab
                      ? 'border-b-2 border-emerald-500 text-emerald-500'
                      : 'text-gray-500'
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>
            {activeTab === 'Personal info' && (
             <Personal/>
            )}
            {activeTab === 'Company' && (
             <Company/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;