import React from 'react'
import Template from '../../components/templates/Template'
import loginimg from "../../assests/Login.png"
export const SignUp = () => {
  return (
    <Template title="Welcome to "
    highlight="Reybot"
    image={loginimg}
    formType="signup"/>
  )
}
