import "./landing.css";
import Navbar from "./components/navbar/Navbar";
import Hero from "./components/hero/Hero";
import Brands from "./components/brands/Brands";
import Section2 from "./components/section2/Section2";
import Section3 from "./components/section3/Section3";
import Section4 from "./components/section4/Section4";
import Section5 from "./components/section5/Section5";
import Section6 from "./components/section6/Section6";
import Section7 from "./components/section7/Section7";
import Section8 from "./components/section8/Section8";
import Section9 from "./components/section9/Section9";
import Section10 from "./components/section10/Section10";
import Credits from "./components/credits/Credits";
import CountryContext from "../context/CountryContext";
import SessionContext from "../context/SessionContext";
import { useEffect, useState, useContext } from "react";
import { toast } from "react-hot-toast";
import "./fonts/fonts.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutAPI } from "../APIServices/usersAPI"; 
import { setToken } from "../redux/slices/authSlice"; 
const Loader = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

function Landing() {
  const { token } = useSelector((state) => state.auth);
  const {sessEmail} = useContext(SessionContext);

  console.log("Token from Landing Pge s",token)
  const [country, setCountry] = useState("England");
  const [curr, setCurr] = useState("£");
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate(); 
  const dispatch = useDispatch(); 


  useEffect(() => {
    const getInfo = async () => {
      try{
        const data = await fetch("https://ipapi.co/json/");
        const ipDetails = await data.json();
        setCountry(ipDetails.country);
        setCurr(ipDetails.currency);
      }
      catch (err) {console.log(err);}
    };
    getInfo();
  }, []);

  useEffect(() => {
    if (!token) {
      setLoading(false); 

    }
    if (sessEmail){
      toast("You are logged in as " + sessEmail,
      {
          icon: '👏',
          style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
              fontFamily:"poppins-med"
              },
      });
  }

  }, [token, sessEmail]);

  const handleLogout = async () => {
    try {
      await logoutAPI();
      
      dispatch(setToken(null));
      localStorage.removeItem("token");

      window.location.reload(); 
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };


//   if (loading) {
//     return <Loader />;
//   }


  return (
    <CountryContext.Provider value={{ country, setCountry, curr, setCurr }}>
      <div className="landing">
        <Navbar onLogout={handleLogout} /> 
        <Hero />
        <Brands />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
        <Section10 />
        <Credits />
      </div>
    </CountryContext.Provider>
  );
}

export default Landing;
