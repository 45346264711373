import "./pricecard.css";
import point from "../../images/forSection10/checkmark.svg";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import CountryContext from "../../../context/CountryContext";

function CardPart(props){
    const {title, desc, price, ym, title2, arr} = props;

    const renderPoints = arr.map((x, ind) =>{
        return (
            <div className="point" key={ind}>
                <img src={point} alt="point" />
                <p>{x}</p>
            </div>
        );
    })

    return (
        <div className="cardpart">
            <p className="title">{title}</p>
            <p className="low">{desc}</p>
            <p className="price">{price} <span>{ym}</span> </p>
            <p className="secTitle">{title2}</p>
            {renderPoints}
            <div className="space"></div>
            <Link to="" className="link">Get Started</Link>
        </div>
    );
}

function PriceCard({isMonthly}){
    const [GoldPrice, setGold] = useState(29.99);
    const [PlatPrice, setPlat] = useState(59.99);
    const [p1, setP1] = useState(5.99);
    const [p2, setP2] = useState(0.19);
    const {setCurr, country, curr} = useContext(CountryContext);

    useEffect(() =>{
        switch(country){
            case "IN":setGold(1500); setPlat(3000); setCurr("₹"); setP1(299); setP2(2); break;
            case "USA":setGold(29.99); setPlat(59.99); setCurr("$"); setP1(5.99); setP2(0.10);break;
            default: setGold(29.99); setPlat(59.99); setCurr("£");setP1(5.99); setP2(0.19); 
        }
    }, [country, setCurr]);

    let dur, yrmul;

    if (isMonthly){
        yrmul=1;
        dur="/month";
    }
    else{
        yrmul=10;
        dur="/year";
    }

    const arr1 = ["Blue tick", "1 user, more users for " +  p1 + " " + curr + "/month", "600 conversations", p2 + " " + curr + " for additional conversation", "Upto 25 contacts"];

    const arr2 = ["Blue tick", "5 users, more users for "+  p1 + " " + curr + "/month", "1000 conversations", p2 + " " + curr +" for additional conversation", "Upto 5000 contacts", "Hub spot integrations", "Al Assistance"];

    const arr3 = ["Blue tick", "5 users, more users for " +  p1 + " " + curr + "/month", "1500 conversations", p2 + " " + curr + " for additional conversation", "Upto 50000 contacts", "Hub spot integrations", "Al Assistance"];

    const arr4 = ["Need customer care resources to support your whatsapp growth"];

    return (
        <div className="pricecard">
            <CardPart title="Premium" desc="Best for personal use" price="Free" ym="" title2="What you get:" arr={arr1} />

            <CardPart title="Gold" desc="Ideal for single shop owners, online businesses" price={Math.round(GoldPrice*yrmul)-0.01 + " " + curr} ym={dur} title2="What you get:" arr={arr2} />

            <CardPart title="Platinum" desc="Ideal for businesses of all sizes, multiple locations" price={Math.round(PlatPrice*yrmul)-0.01 + " " + curr} ym={dur} title2="What you get:" arr={arr3} />

            <CardPart title="Custom" desc="Best for personal use" price={"Flexible"} ym={dur} title2="Contact Us" arr={arr4} />
        </div>
    );
}

export default PriceCard;