import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { loginAPI } from '../../APIServices/usersAPI';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import toast from 'react-hot-toast';
import SessionContext from "../../context/SessionContext";
import { useContext } from "react";
import { useDispatch } from 'react-redux';  
import { setToken } from '../../redux/slices/authSlice';


export const LoginForm = () => {
  const {setSessEmail} = useContext(SessionContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();  
  const [showPassword, setShowPassword] = React.useState(false);

  const userMutation = useMutation({
    mutationFn: loginAPI,
    onSuccess: (data) => {
      localStorage.setItem("email", data.user.email);
      setSessEmail(data.user.email);
      const token = data.token;
      // console.log("Token is from login",token)
      if (data.user.access_token){
        localStorage.setItem("accessToken", "true");
      }
      else{
        localStorage.setItem("accessToken", "false");
      }

      dispatch(setToken(token));

      toast.success("Login Successfully");
      // Check the exact place to call this after login
      // TODO: https://reybot-backend.onrender.com/api/v1/users/get-long-lived-access-token
      // SAVE IN STATE - APP ID , PHONE ID, ACCESS TOKEN

      // setTimeout(() => {
      //   navigate('/user-dashboard');  // Redirect to the dashboard after successful login
      // }, 2000); 
    },
    onError: (error) => {
      toast.error('Login Error');
      console.error('Login Error:', error);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Enter a valid email').required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: (values) => {
      userMutation.mutate(values);
    },
  });

  return (
    <div className="w-full max-w-md mx-auto bg-white shadow-lg p-6 rounded-lg">
      <form onSubmit={formik.handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Email Address</label>
          <input
            type="text"
            placeholder="Email address"
            {...formik.getFieldProps('email')}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
          )}
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700">Password</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              {...formik.getFieldProps('password')}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.password}</div>
            )}
          </div>
        </div>

        <div>
          <button
            type="submit"
            disabled={userMutation.isLoading}
            className="w-full py-2 px-4 bg-green-500 text-white font-semibold rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
          >
            {userMutation.isLoading ? 'Logging in...' : 'Login ›'}
          </button>
        </div>
      </form>

      <div className="mt-4 text-center">
        <p className="text-sm text-gray-600">
          Need an account? <Link className="text-green-500 hover:underline" to="/signup">Create one</Link>
        </p>
      </div>
    </div>
  );
};
