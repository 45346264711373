import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { verifyOTPAPI } from '../APIServices/usersAPI';
import { FiEdit2, FiCheck } from 'react-icons/fi'; 
import loginimg from "../assests/Login.png";
import logo from "../assests/logo.png";
import toast from 'react-hot-toast';

export const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialEmail = location.state?.email;
  const password = location.state?.password;
  const contactNo = location.state?.contactNo;
  const [isEditing, setIsEditing] = useState(false); 
  const [email, setEmail] = useState(initialEmail);
  
  // Timer state and handling resend logic
  const [timeLeft, setTimeLeft] = useState(25); // Timer for OTP resend
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else {
      setIsResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);

  const handleResendOTP = () => {
    // Reset timer when resend is clicked
    setTimeLeft(25);
    setIsResendDisabled(true);
    toast.success("OTP Resent Successfully");
    // Your resend OTP logic here
  };

  const verifyMutation = useMutation({
    mutationFn: verifyOTPAPI,
    onSuccess: () => {
      toast.success("User Registered Successfully");
      setTimeout(() => {
        navigate('/login');
      }, 2000); 
    },
    onError: (error) => {
      toast.error("OTP Verification Failed");
      console.error('OTP Verification Error:', error);
    },
  });

  const formik = useFormik({
    initialValues: {
      otp: ['', '', '', '', '', ''],
    },
    validationSchema: Yup.object({
      otp: Yup.array().of(Yup.string().required()).length(6),
    }),
    onSubmit: (values) => {
      const otp = values.otp.join('');
      toast.loading("Verifying OTP...", { duration: 2000 });
      verifyMutation.mutate({ email, otp, password, contactNo });
    },
  });

  const handleOtpChange = (index, value) => {
    const newOtp = [...formik.values.otp];
    newOtp[index] = value;
    formik.setFieldValue('otp', newOtp);

    if (value && index < 5) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="flex h-screen">
      {/* Left side image full-screen */}
      <div className="w-1/2 bg-teal-500 flex items-center justify-center relative">
        <img src={loginimg} alt="Bot Image" className="absolute inset-0 h-full w-full object-cover" />
      </div>
      
      {/* Right side form */}
      <div className="w-1/2 flex flex-col items-center justify-center relative">
        <div className="bg-white p-8 rounded-lg shadow-lg w-96">
          <div className="flex justify-start mb-6">
            <img src={logo} alt="Reybot Logo" className="h-10" />
          </div>
          <h2 className="text-2xl font-bold mb-6">We sent an email to:</h2>
          <p className="text-sm text-gray-600 mb-4">to continue to Reybot</p>

          <div className="flex items-center bg-green-100 p-2 rounded-md mb-6">
            {isEditing ? (
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                className="bg-green-100 text-green-600 font-medium text-lg border-none outline-none w-full"
              />
            ) : (
              <span className="text-green-600 font-medium text-lg">{email}</span>
            )}
            <button onClick={toggleEditMode} className="ml-2 text-green-600 cursor-pointer">
              {isEditing ? <FiCheck /> : <FiEdit2 />}
            </button>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Verification Code
              </label>
              <p className="text-sm text-gray-500 mb-4">Enter 6 digit verification code sent to your email</p>
              <div className="flex space-x-2">
                {[0, 1, 2, 3, 4, 5].map((index) => (
                  <input
                    key={index}
                    id={`otp-${index}`}
                    type="text"
                    maxLength="1"
                    className="w-12 h-12 text-center border-2 border-gray-300 rounded-md focus:border-green-500 focus:ring focus:ring-green-200 focus:ring-opacity-50 text-lg"
                    value={formik.values.otp[index]}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                  />
                ))}
              </div>
            </div>
            <button
              type="submit"
              disabled={verifyMutation.isLoading}
              className="w-full bg-green-600 text-white py-2 rounded-lg hover:bg-green-700 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed text-lg"
            >
              {verifyMutation.isLoading ? 'Verifying...' : 'Verify'}
            </button>
          </form>

          <div className="text-center mt-4">
            <p className="text-sm text-gray-600">
              Didn't receive a code?{' '}
              <button
                onClick={handleResendOTP}
                disabled={isResendDisabled}
                className="text-green-600 hover:underline focus:outline-none disabled:opacity-50"
              >
                Resend {isResendDisabled ? `(${timeLeft})` : ''}
              </button>
            </p>
           
          </div>
        </div>
      </div>
    </div>
  );
};
