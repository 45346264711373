import React, { useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SessionContext from "../context/SessionContext";
import { generateLongLivedAccessTokenAPI } from "../APIServices/usersAPI";

const MetaOAuth = () => {
  const navigate = useNavigate();

  const { setAccessToken, setPhoneId } = useContext(SessionContext);

  const handleMetaLogin = () => {
    const appId = "815620406907245";
    const redirectUri = "https://app2.reybot.co.uk";
    const scope = "business_management";

    window.location.href = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&scope=${scope}`;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    console.log("Code", code);
    localStorage.setItem("Code", code);

    if (code) {
      const getAccessToken = async () => {
        try {
          const response = await axios.get(
            `https://graph.facebook.com/v15.0/oauth/access_token`,
            {
              params: {
                client_id: "815620406907245", // Meta App ID
                redirect_uri: "https://app2.reybot.co.uk", // Redirect URI
                client_secret: process.env.NEXT_PUBLIC_META_APP_SECRET,

                code: code,
              },
            }
          );
          console.log("response", response);
          console.log("response JSON", JSON.stringify(response));
          localStorage.setItem("response", JSON.stringify(response));

          console.log("response.data", response.data);
          console.log("response.dataJSON", JSON.stringify(response.data));
          localStorage.setItem("response.data", JSON.stringify(response.data));
          //    console.log(process.env.NEXT_PUBLIC_META_APP_SECRET)
          const accessToken = response.data.access_token;
          console.log("access_token", response.data.access_token);
          console.log(
            "access_token JSON",
            JSON.stringify(response.data.access_token)
          );
          localStorage.setItem("access_token", response.data.access_token);
          // TODO: https://reybot-backend.onrender.com/api/v1/users/generate-long-lived-access-token

          const app_id = response.data?.app_id || "";
          const phone_id = response.data?.phone_id || "";
          // SAVE IN STATE - APP ID , PHONE ID, ACCESS TOKEN
          setAccessToken(accessToken);
          setPhoneId(phone_id);

          // TODO: CALL SAVE AND GENERATE LONG LIVED ACCES TOKEN
          const data = await generateLongLivedAccessTokenAPI(
            accessToken,
            app_id,
            phone_id
          );

          // SAVE LONG LIVED ACCESS TOKEN IN STATE
          console.log("data", data);
          setAccessToken(data.access_token);
          setPhoneId(data.phone_id);

          localStorage.setItem("accessToken", "true");

          navigate("/dashboard");
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      };

      getAccessToken();
    }
  }, [navigate]);

  return (
    <div>
      <button onClick={handleMetaLogin}>Connect with Meta Business</button>
      <h1>Processing...</h1>
    </div>
  );
};

export default MetaOAuth;
