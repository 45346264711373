import "./maincard.css";

function MainCard() {
  return (
    <div className="maincard">
      <p>
        Experience seamless navigation and intuitive design with our neat and
        clean user interface. Enjoy an effortless user experience that enhances
        productivity and accessibility, making interaction with our platform a
        pleasure.
      </p>

      <p>
        Our platform offers a range of essential business pages designed to
        streamline your operations. From dashboard overviews to in-depth
        analytics, these pages are optimized to support your business's needs
        and ensure efficient workflows.
      </p>

      <p>
        Reybot integrates seamlessly with various third-party tools, enhancing
        your workflow and enabling automated operations. Whether it's CRM
        systems, marketing platforms, or customer service tools, our
        integrations ensure you have everything you need in one place.
      </p>
    </div>
  );
}

export default MainCard;
