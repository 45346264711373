import React from 'react'
import { Navigate } from 'react-router-dom';

const AccessTokenRoute = ({children}) => {

    if(localStorage.getItem("accessToken")==="true"){
        return children;
    }
    else{
        return <Navigate to="/dashboard" />;
    }
}

export default AccessTokenRoute;
