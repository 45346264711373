import React from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { registerAPI } from "../../APIServices/usersAPI";
import {
  FaGoogle,
  FaApple,
  FaFacebookF,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import toast from "react-hot-toast";
import { countryCodes } from "../../data/countryCode";
import { useDispatch } from "react-redux";
import { setToken } from "../../redux/slices/authSlice";

export const SignUpForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);

  const userMutation = useMutation({
    mutationFn: registerAPI,
    onSuccess: (data) => {
      toast.success("OTP Sent Successfully");
      navigate("/verify-otp", {
        state: {
          email: formik.values.email,
          password: formik.values.password,
          contactNo: formik.values.contactNo,
        },
      });
    },
    onError: (error) => {
      if (error.response.data.message==="User Already Exists"){
        toast.error("This Email-Id is already registered!");
      }
      else{
        toast.error("Registration failed");
      }
      console.error("Registration Error:", error);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      contactNo: "",
      countryCode: countryCodes[0].value,
      privacyConsent: false,
      marketingOptIn: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Enter a valid email")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
      contactNo: Yup.string().required("Contact Number is required"),
      countryCode: Yup.string().required("Country code is required"),
      privacyConsent: Yup.boolean().oneOf(
        [true],
        "You must accept the Privacy and Cookie Policy"
      ),
      marketingOptIn: Yup.boolean().oneOf(
        [true],
        "You must accept the Marketing Communications"
      ),
    }),
    onSubmit: (values) => {
      userMutation.mutate(values); // Submit form
    },
  });

  return (
    <div className="w-full max-w-md mx-auto bg-white shadow-lg p-6 rounded-lg">
      <form onSubmit={formik.handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email Address
          </label>
          <input
            type="text"
            placeholder="Email address"
            {...formik.getFieldProps("email")}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.email}
            </div>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Phone Number
          </label>
          <div className="flex space-x-2">
            <div className="w-1/3">
              <Select
                options={countryCodes}
                onChange={(option) =>
                  formik.setFieldValue("countryCode", option.value)
                }
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={countryCodes[0]}
              />
            </div>
            <div className="w-3/4">
              <input
                type="text"
                placeholder="Phone Number"
                {...formik.getFieldProps("contactNo")}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
              />
              {formik.touched.contactNo && formik.errors.contactNo && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.contactNo}
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              {...formik.getFieldProps("password")}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.password}
              </div>
            )}
          </div>
        </div>

        {/* Privacy Policy and Cookie Consent */}
        <div className="flex items-center">
          <input
            id="privacyConsent"
            type="checkbox"
            {...formik.getFieldProps("privacyConsent")}
            className="mr-2"
          />
          <label htmlFor="privacyConsent" className="text-sm text-gray-600">
            I agree to the{" "}
            <Link
              to="/privacy-policy"
              className="text-green-500 hover:underline"
            >
              Privacy Policy and Cookie Policy
            </Link>
            .
          </label>
          {formik.touched.privacyConsent && formik.errors.privacyConsent && (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.privacyConsent}
            </div>
          )}
        </div>

        {/* Optional: Marketing Opt-In */}
        <div className="flex items-center">
          <input
            id="marketingOptIn"
            type="checkbox"
            {...formik.getFieldProps("marketingOptIn")}
            className="mr-2"
          />
          <label htmlFor="marketingOptIn" className="text-sm text-gray-600">
            I agree to receive marketing communications.
          </label>
          {formik.touched.marketingOptIn && formik.errors.marketingOptIn && (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.marketingOptIn}
            </div>
          )}
        </div>

        <div>
          <button
            type="submit"
            disabled={userMutation.isLoading}
            className="w-full py-2 px-4 bg-green-500 text-white font-semibold rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-200"
          >
            {userMutation.isLoading ? "Creating Account..." : "Continue ›"}
          </button>
        </div>
      </form>

      <div className="mt-4 text-center">
        <p className="text-sm text-gray-600">
          Have an account?
          <Link to="/login" className="text-green-500 hover:underline">
            Sign In
          </Link>
        </p>
      </div>
    </div>
  );
};