import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import {
  getProfileAPI,
  updateProfileAPI,
  updatePasswordAPI,
} from "../../APIServices/usersAPI";
import { countryCodes } from "../../data/countryCode";
const defaultCountryCode = countryCodes.find((c) => c.value === "+44");

export const Personal = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["fetch-profile"],
    queryFn: getProfileAPI,
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contactNo: "",
    email: "",
    countryCode: defaultCountryCode || countryCodes[0],
    newPassword: "",
    confirmPassword: "",
  });
  const [isProfileEditing, setIsProfileEditing] = useState(false);
  const [isPasswordEditing, setIsPasswordEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (data?.userDetails) {
      setFormData({
        firstName: data.userDetails.firstName || "",
        lastName: data.userDetails.lastName || "",
        contactNo: data.userDetails.contactNo || "",
        email: data.userDetails.email || "",
        countryCode:
          countryCodes.find((c) => c.value === data.userDetails.countryCode) ||
          defaultCountryCode,
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const profileMutation = useMutation({
    mutationFn: updateProfileAPI,
    onMutate: () => {
      setIsSaving(true);
    },
    onSuccess: (response) => {
      toast.success("Profile updated successfully");
      setIsSaving(false);
      setIsProfileEditing(false);
    },
    onError: (error) => {
      toast.error("Failed to update profile");
      console.error("Profile update error:", error);
      setIsSaving(false);
    },
  });

  const passwordMutation = useMutation({
    mutationFn: updatePasswordAPI,
    onMutate: () => {
      setIsSaving(true);
    },
    onSuccess: (response) => {
      toast.success("Password updated successfully");
      setIsSaving(false);
      setIsPasswordEditing(false);
    },
    onError: (error) => {
      toast.error("Failed to update password");
      console.error("Password update error:", error);
      setIsSaving(false);
    },
  });

  const handleProfileSubmit = (e) => {
    e.preventDefault();
    profileMutation.mutate(formData);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    passwordMutation.mutate(formData);
  };

  const handleCancelProfile = () => {
    setFormData(data?.userDetails || {});
    setIsProfileEditing(false);
  };

  const handleCancelPassword = () => {
    setFormData({
      ...formData,
      newPassword: "",
      confirmPassword: "",
    });
    setIsPasswordEditing(false);
  };

  return (
    <div className="p-10 w-full mx-auto bg-white rounded-lg shadow-xl">
      <h2 className="text-xl font-semibold text-gray-800 mb-6">
        Personal Information
      </h2>

      <div className="text-sm text-gray-500 mb-4">
        You can change your personal information settings here.
      </div>

      {/* Profile Section */}
      <form className="space-y-6" onSubmit={handleProfileSubmit}>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              disabled={!isProfileEditing}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              disabled={!isProfileEditing}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 p-2"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Phone Number
          </label>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <Select
                options={countryCodes}
                onChange={(option) =>
                  setFormData({ ...formData, countryCode: option.value })
                }
                className="react-select-container w-full"
                classNamePrefix="react-select"
                defaultValue={formData.countryCode}
                isDisabled={!isProfileEditing}
              />
            </div>
            <div>
              <input
                type="text"
                name="contactNo"
                value={formData.contactNo}
                onChange={handleInputChange}
                disabled={!isProfileEditing}
                className="block w-full rounded-md border-gray-300 focus:border-emerald-500 focus:ring-emerald-500 p-2"
              />
            </div>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Business Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            disabled={!isProfileEditing}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 p-2"
          />
        </div>

        {isProfileEditing ? (
          <div className="flex justify-end items-center space-x-4">
            <button
              type="button"
              onClick={handleCancelProfile}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md text-sm font-medium hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-emerald-600 text-white rounded-md text-sm font-medium hover:bg-emerald-500"
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        ) : (
          <button
            type="button"
            onClick={() => setIsProfileEditing(true)}
            className="px-4 py-2 bg-emerald-600 text-white rounded-md text-sm font-medium hover:bg-emerald-500"
          >
            Edit Profile
          </button>
        )}
      </form>

      {/* Password Section */}
      <form className="space-y-6 mt-8" onSubmit={handlePasswordSubmit}>
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          Update Password
        </h2>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <input
              type="password"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleInputChange}
              disabled={!isPasswordEditing}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Confirm Password
            </label>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              disabled={!isPasswordEditing}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 p-2"
            />
          </div>
        </div>

        {isPasswordEditing ? (
          <div className="flex justify-end items-center space-x-4">
            <button
              type="button"
              onClick={handleCancelPassword}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md text-sm font-medium hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-emerald-600 text-white rounded-md text-sm font-medium hover:bg-emerald-500"
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        ) : (
          <button
            type="button"
            onClick={() => setIsPasswordEditing(true)}
            className="px-4 py-2 bg-emerald-600 text-white rounded-md text-sm font-medium hover:bg-emerald-500"
          >
            Edit Password
          </button>
        )}
      </form>
    </div>
  );
};
