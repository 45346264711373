import React, { useState, useEffect } from "react";
import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import { BASE_URL } from "../../utils/endPoint/baseUrl";
import { useContext } from "react";
// import SessionContext from "../../context/SessionContext";



import { 
  getMetaStorageValues
} from '../../context/getAccessTokenData';

const Table = ({ editRow }) => {
  const [templates, setTemplates] = useState([]);
  // const { accessToken } = useContext(SessionContext);

  
  const { 
    accessToken 
  } = getMetaStorageValues();


  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await fetch(`${BASE_URL}/templates`, {
        method: "GET",
        headers: {'Content-Type':'application/json'},
        credentials: "include"
      });
      const result = await response.json();

      if (result.data && Array.isArray(result.data)) {
        const templatesWithStatus = await Promise.all(
          result.data.map(async (template) => {
            const status = await fetchFacebookTemplateStatus(
              template.facebookTemplateId
            );
            return { ...template, status };
          })
        );
        setTemplates(templatesWithStatus);
      } else {
        console.error("API response does not contain a 'data' array:", result);
        setTemplates([]);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
      setTemplates([]);
    }
  };

  const fetchFacebookTemplateStatus = async (facebookTemplateId) => {
    if (!facebookTemplateId) return "PENDING";
    try {
      const response = await fetch(
        `https://graph.facebook.com/v20.0/${facebookTemplateId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      return data.status || "PENDING";
    } catch (error) {
      console.error("Error fetching Facebook template status:", error);
      return "PENDING";
    }
  };

  const deleteRow = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/templates/${id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        // Refresh the templates list after successful deletion
        fetchTemplates();
      } else {
        console.error("Failed to delete template");
      }
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };

  const getStatusStyles = (status) => {
    switch (status) {
      case "REJECTED":
        return "bg-red-500 text-white rounded-md px-2 py-1";
      case "APPROVED":
        return "bg-green-500 text-white rounded-md px-2 py-1";
      case "PENDING":
        return "bg-blue-500 text-white rounded-md px-2 py-1";
      default:
        return "";
    }
  };

  return (
    <div className="w-[75vw] mx-auto rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Template Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Category
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Language
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Status
            </th>{" "}
            {/* New STATUS column */}
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {templates.map((row) => (
            <tr key={row._id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {row.template}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {row.category}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {row.Language}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <span className={getStatusStyles(row.status)}>
                  {row.status}
                </span>{" "}
                {/* Display status */}
              </td>
              <td className="px-6 py-4 whitespace-nowrap relative -left-16">
                <span className="actions flex justify-center items-center gap-4">
                  <BsFillTrashFill
                    className="delete-btn"
                    onClick={() => deleteRow(row._id)}
                  />
                  <BsFillPencilFill
                    className="edit-btn"
                    onClick={() => editRow(row._id)}
                  />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
