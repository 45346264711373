import "./headercard.css";

function HeaderCard(){
    return (
        <div className="headercard">
            <div><p>01</p><p>Clean User Interface</p></div>
            <div><p>02</p><p>Essential Business Pages</p></div>
            <div><p>03</p><p>Fully Functional Integrations</p></div>
        </div>
    );
}

export default HeaderCard;