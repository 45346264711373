import "./analytics.css";
import Topbar from "../Contact Page/components/topbar/Topbar";
import mag from "../assests/analytics/search-normal.svg";
import down from "../assests/analytics/down.svg";
import banner from "../assests/analytics/pana.png";
import { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BASE_URL } from "../utils/endPoint/baseUrl";

function Calender(){
    const [selectedDate, setDate] = useState(new Date());
    const [upOrDown, setUpOrDown] = useState("none");
    const [isOpen, setOpen] = useState(false);

    const handleClick = () =>{
        if (isOpen){
            setOpen(false);
            setUpOrDown("none");
        }
        else{
            setOpen(true);
            setUpOrDown("rotate(180deg)");
        }
    }

    return (
        <div className="calender">
            <div style={{display:"flex"}} onClick={handleClick}>
                <p className="dateDisplay">{selectedDate.toDateString()}</p>
                <img src={down} alt="arrow" style={{transform:upOrDown}}/>
            </div>
            {isOpen && (
                <DatePicker 
                    selected={selectedDate} 
                    onChange={(date) =>{setDate(date); setOpen(false); setUpOrDown("none");}} 
                    showIcon
                />)
            }
        </div>
    );
}

function Analytics(){
    const [analyticsData, setAnalyticsData] = useState([
        {metricName:"Total Conversations Sent", metricValue:100, insight:"+17% vs last month"},
        {metricName:"Total Messages Read", metricValue:72, insight:"+7% vs last month"},
        {metricName:"Total Messages Responded To", metricValue:14, insight:"-2% vs last month"},
        {metricName:"Total Subscribers", metricValue:9, insight:"-20 vs last month"},
        {metricName:"Active Campains", metricValue:3, insight:"+50 vs last month"},
        {metricName:"Total Leads Generated", metricValue:11, insight:"+50 vs last month"},
        {metricName:"Total Messages Sent", metricValue:10, insight:"+8% vs last month"}
    ]);

    useEffect(() =>{
        fetch(`${BASE_URL}/v1/analytics`, {
            method: "GET",
            headers: {'Content-Type':'application/json'},
            credentials: "include"
        })
        .then(res1 =>res1.json())
        .then(res2 =>{
            if (!res2.success){
                console.log("success false");
            }
            else{
                setAnalyticsData([
                    {metricName:"Total Conversations Sent", metricValue:res2.arr.conversationCount, insight:"+17% vs last month"},
                    {metricName:"Total Messages Read", metricValue:res2.arr.messagesRead, insight:"+7% vs last month"},
                    {metricName:"Total Messages Responded To", metricValue:res2.arr.responseCount, insight:"-2% vs last month"},
                    {metricName:"Total Subscribers", metricValue:res2.arr.totalSubscribers, insight:"-20 vs last month"},
                    {metricName:"Active Campains", metricValue:res2.arr.activeCampaigns, insight:"+50 vs last month"},
                    {metricName:"Total Messages Sent", metricValue:res2.arr.messagesSent, insight:"+8% vs last month"}
                ]);
            }
        })
    }, []);

    const renderAllMetrics = analyticsData.map((x, ind) =>{
        return (
            <div className="metric" key={ind}>
                <p className="metricName">{x.metricName}</p>
                <p className="metricValue">{x.metricValue}</p>
                <p className="dataInsight">{x.insight}</p>
            </div>
        );
    });

    return (
        <>
            <Topbar />
            <div className="analytics">
                <p className="header">Analytics</p>

                <div className="searchFilter">
                    <div className="search">
                        <img src={mag} alt="search" />
                        <input type="text" name="search" placeholder="Find actions" autoComplete="off" />
                    </div>

                    <div className="dateSelector">
                        <p className="ind">From</p>
                        <Calender />
                    </div>

                    <div className="dateSelector">
                        <p className="ind">To</p>
                        <Calender />
                    </div>
                </div>

                <div className="metrics">
                    {renderAllMetrics}
                </div>

                <div className="banner">
                    <div className="txt">
                        <p>Create your First Bot</p>
                        <p>Your free trial expired in 15 days</p>
                        <p>Upgrade Plan</p>
                    </div>
                    <img src={banner} alt="banner" />
                </div>
            </div>
        </>
    );
}

export default Analytics;