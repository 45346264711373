import React, { useState, useEffect } from 'react';
import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import axios from 'axios';
import { BASE_URL } from '../../utils/endPoint/baseUrl';

const Table = ({ deleteRow, editRow }) => {
  const [broadcasts, setBroadcasts] = useState([]);

  useEffect(() => {
    // Fetching the broadcast data from the API
    const fetchBroadcasts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/broadcasts`, {withCredentials: true});
        setBroadcasts(response.data.data);
      } catch (error) {
        console.error('Error fetching broadcasts:', error);
      }
    };

    fetchBroadcasts();
  }, []);

  return (
    <div className="w-[75vw] mx-auto rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">BROADCAST NAME</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">CATEGORY</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SCHEDULED</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {broadcasts.map((row, idx) => (
            <tr key={row._id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{row.template}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{row.title}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
               {new Date(row.scheduledDate).toLocaleDateString()}
              </td>
              <td className="px-6 py-4 whitespace-nowrap relative -left-16">
                <span className="actions flex justify-center items-center gap-4">
                  <BsFillTrashFill
                    className="delete-btn"
                    onClick={() => deleteRow(idx)}
                  />
                  <BsFillPencilFill
                    className="edit-btn"
                    onClick={() => editRow(idx)}
                  />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
