import { useState, useEffect } from 'react';
import { Modal } from "../components/TemplateCompo/Model";
import  Table from "../components/TemplateCompo/Table";
import { BASE_URL } from '../utils/endPoint/baseUrl';


const TemplatesPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [templates, setTemplates] = useState([]);

  const [rowToEdit, setRowToEdit] = useState(null);

  const handleDeleteRow = async(targetIndex) => {
    const response = await fetch(`${BASE_URL}/templates/${targetIndex}`, {
      method: 'DELETE',
    });

    if (response.ok) {
      setTemplates(templates.filter((_, index) => index !== targetIndex));
      console.log('Template deleted successfully');
    } else {
      console.error('Failed to delete template');
    }
    
  };

  const handleEditRow = (idx) => {
    setRowToEdit(idx);
    setModalOpen(true);
  };

  const handleSubmit = (newRow) => {


    
    setModalOpen(false);
    setRowToEdit(null);
  };

  return (
   <>
    <header className="bg-white shadow-sm" style={{fontFamily: "poppins-reg"}}>
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" style={{fontFamily: "poppins-reg"}}>
      <div className="flex justify-between items-center py-3">
        <div className="flex-1 min-w-0">
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>
            <input
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Search for anything..."
              />
          </div>
        </div>
        <div className="ml-4 flex items-center md:ml-6">
          <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span className="sr-only">View notifications</span>
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
          </button>

          <div className="ml-3 relative">
            <div>
              <button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" id="user-menu" aria-expanded="false" aria-haspopup="true">
                <span className="sr-only">Open user menu</span>
                <img className="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>



    <div className="bg-gray-100 min-h-screen p-6" style={{fontFamily: "poppins-reg"}}>
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h1 className="text-2xl font-bold text-gray-800">Templates</h1>
            <p className="text-sm text-gray-600 mt-1 w-3/4">
              This feature showcases all the message templates you have designed and saved. You can easily
              access, edit, and reuse these templates to streamline your communication process and maintain
              consistency in your messaging.
            </p>
          </div>
          <button className="bg-green-500 hover:bg-green-600 text-white text-sm font-medium py-2 px-4 rounded w-1/4 "
          onClick={() => setModalOpen(true)}
          type="button">
            New Template Message
          </button>
        </div>

        <div className="bg-white rounded-lg shadow">
        <Table deleteRow={handleDeleteRow} editRow={handleEditRow} />
        {modalOpen && (
            <Modal
              closeModal={() => {
                setModalOpen(false);
                setRowToEdit(null);
              }}
              onSubmit={handleSubmit}
              defaultValue={rowToEdit !== null ? templates[rowToEdit] : undefined}
            />
          )}
        </div>
      </div>
    </div>
   </>
  );
};

export default TemplatesPage;