import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { SignUp } from "./pages/Auth/Signup";
import { VerifyEmail } from "./pages/VerifyEmail";
import DashboardPage from "./pages/Dashboard";
import MetaOAuth from "./pages/MetaAuth";
import WhatsAppMessageSender from "./pages/SendMessage";
import Sidebar from "./pages/Sidebar";
import ClientHome from "./pages/ClientHome";
import { Login } from "./pages/Auth/Login";
import ProfileTabs from "./pages/Setting";
import Landing from "./Landing Page/Landing";
import Contact from "./Contact Page/Contact";
import CookieConsentBanner from "./components/common/CookieScreen";
import OpenRoute from "./Private/OpenRoute";
import PrivateRoute from "./Private/PrivateRoute";
import Template from "./pages/Template";
import BoardCast from "./pages/BoardCast";
import Inbox from "./pages/Inbox";
import Analytics from "./pages/Analytics";
import SessionContext from "./context/SessionContext";
import { useEffect, useState } from "react";
import AccessTokenRoute from "./Private/AccessTokenRoute";
import PrivacyPolicy from "./pages/Privacy";
import SupportPage from "./pages/Support";

function App() {
  const [sessEmail, setSessEmail] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [phoneId, setPhoneId] = useState("");
  const location = useLocation();
  const isLandingPage = location.pathname === "/";
  const isSignUpPage = location.pathname === "/signup";
  const isVerifyPage = location.pathname === "/verify-otp";
  const isLoginPage = location.pathname === "/login";
  const isPrivacyPolicyPage = location.pathname === "/privacypolicy";
  const isSupportPage = location.pathname === "/support";

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setSessEmail(storedEmail);
    }
  }, []);

  useEffect(() => {
    if (sessEmail) {
      console.log("You are logged in as " + sessEmail);
    }
  }, [sessEmail]);

  return (
    <SessionContext.Provider
      value={{
        sessEmail,
        setSessEmail,
        accessToken,
        setAccessToken,
        phoneId,
        setPhoneId,
      }}
    >
      <div className="flex">
        {/* Sidebar should not be rendered on Landing, Signup, Verify, and Login pages */}
        {!isLandingPage &&
          !isSignUpPage &&
          !isVerifyPage &&
          !isLoginPage &&
          !isSupportPage &&
          !isPrivacyPolicyPage && <Sidebar />}

        <div
          className={`flex-grow ${
            !isLandingPage && !isSignUpPage ? "ml-0" : ""
          }`}
        >
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route
              path="/signup"
              element={
                <OpenRoute>
                  <SignUp />
                </OpenRoute>
              }
            />
            <Route
              path="/login"
              element={
                <OpenRoute>
                  <Login />
                </OpenRoute>
              }
            />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/support" element={<SupportPage />} />
            <Route
              path="/verify-otp"
              element={
                <OpenRoute>
                  <VerifyEmail />
                </OpenRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <DashboardPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <AccessTokenRoute>
                    <ProfileTabs />
                  </AccessTokenRoute>
                </PrivateRoute>
              }
            />
            <Route
              path="/onboard"
              element={
                <PrivateRoute>
                  <MetaOAuth />
                </PrivateRoute>
              }
            />
            <Route
              path="/send-message"
              element={
                <PrivateRoute>
                  <WhatsAppMessageSender />
                </PrivateRoute>
              }
            />
            <Route
              path="/user-dashboard"
              element={
                <PrivateRoute>
                  <ClientHome />
                </PrivateRoute>
              }
            />
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <AccessTokenRoute>
                    <ClientHome />
                  </AccessTokenRoute>
                </PrivateRoute>
              }
            />
            <Route
              path="/templates"
              element={
                <PrivateRoute>
                  <AccessTokenRoute>
                    <Template />
                  </AccessTokenRoute>
                </PrivateRoute>
              }
            />
            <Route
              path="/broadcast"
              element={
                <PrivateRoute>
                  <AccessTokenRoute>
                    <BoardCast />
                  </AccessTokenRoute>
                </PrivateRoute>
              }
            />
            <Route
              path="/inbox"
              element={
                <PrivateRoute>
                  <AccessTokenRoute>
                    <Inbox />
                  </AccessTokenRoute>
                </PrivateRoute>
              }
            />
            <Route
              path="/contacts"
              element={
                <PrivateRoute>
                  <AccessTokenRoute>
                    <Contact />
                  </AccessTokenRoute>
                </PrivateRoute>
              }
            />
            <Route
              path="/analytics"
              element={
                <PrivateRoute>
                  <AccessTokenRoute>
                    <Analytics />
                  </AccessTokenRoute>
                </PrivateRoute>
              }
            />
          </Routes>

          {isLandingPage && <CookieConsentBanner />}
        </div>
      </div>
    </SessionContext.Provider>
  );
}

export default App;
