import { useState, useContext, useEffect } from "react";
import arrow from "../../images/forNavbar/arrow.svg";
import UK from "../../images/forNavbar/United Kingdom.png";
import "./dropdown.css";
import India from "../../images/forNavbar/India.png";
import US from "../../images/forNavbar/US.png";
import CountryContext from "../../../context/CountryContext";

function Dropdown(){
    const [top, setTop] = useState(UK);
    const [isOpen, setOpen] = useState(false);
    const [anim, setAnim] = useState("none");
    const arr = [{one:India, two:"IN", three:"IN"}, {one:US, two:"US", three:"USA"}, {one:UK, two:"UK", three:"ENG"}];

    const {country, setCountry} = useContext(CountryContext);
    useEffect(() =>{
        switch(country){
            case 'IN':setTop(India);break;
            case 'USA':setTop(US);break;
            default: setTop(UK);
        }
    }, [country]);

    const handleClick = () =>{
        if (isOpen){
            setOpen(false);
            setAnim("none");
        }
        else{
            setOpen(true);
            setAnim("rotate(180deg)")
        }
    }

    const handleSelect = (val, cc) =>{
        setCountry(cc);
        setTop(val);
        setOpen(false);
        setAnim("none");
    }

    const renderOpts = arr.map((x, ind) =>{
        return (<div onClick={() =>{handleSelect(x.one, x.three)}} key={ind}><img src={x.one} alt="flag"/><p>{x.two}</p></div>);
    })

    return (
        <div className="dropdown">
            <div className="front" onClick={handleClick}>
                <img src={top} alt="flag" className="flag"/>
                <img src={arrow} alt="arrow" className="arrow" style={{transform:anim}}/>
            </div>
            {isOpen && (
                <div className="below">
                    {renderOpts}
                </div>
            )}
        </div>
    );
}

export default Dropdown;