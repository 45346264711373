
import CountryFlag from 'react-country-flag';

export const countryCodes = [
  { value: '+91', label: <><CountryFlag countryCode="IN" svg /> +91</> },
  { value: '+1', label: <><CountryFlag countryCode="US" svg />  +1</> },
  { value: '+44', label: <><CountryFlag countryCode="GB" svg /> +44</> },
  { value: '+61', label: <><CountryFlag countryCode="AU" svg /> +61</> },
  { value: '+81', label: <><CountryFlag countryCode="JP" svg /> +81</> },
  { value: '+49', label: <><CountryFlag countryCode="DE" svg />  +49</> },
  { value: '+33', label: <><CountryFlag countryCode="FR" svg />  +33</> },
  { value: '+39', label: <><CountryFlag countryCode="IT" svg />  +39</> },
  { value: '+34', label: <><CountryFlag countryCode="ES" svg />  +34</> },
  { value: '+86', label: <><CountryFlag countryCode="CN" svg />  +86</> },
  { value: '+7', label: <><CountryFlag countryCode="RU" svg /> +7</> },
];
