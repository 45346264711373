import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./redux/reducer";
import { Provider } from "react-redux";
const queryClient=new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
const store=configureStore({
  reducer:rootReducer,
})
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <QueryClientProvider client={queryClient}>
<BrowserRouter>
 <App />
 <Toaster/>
</BrowserRouter>

    </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);
