import "./addcontact.css";
import back from "../../images/forAddContact/back.svg";
import acc from "../../images/forAddContact/acc.svg";
import mail from "../../images/forAddContact/mail.svg";
import uk from "../../images/forAddContact/uk.svg";
import india from "../../images/forAddContact/india.webp";
import usa from "../../images/forAddContact/usa.png";
import down from "../../images/forAddContact/down.svg";
import { useEffect, useState, useContext } from "react";
import { toast } from "react-hot-toast";
import SessionContext from "../../../context/SessionContext";
import { BASE_URL } from "../../../utils/endPoint/baseUrl";


function CountryDD({setCall, setValues}){
    const [isOpen, setOpen] = useState(false);
    const [top, setTop] = useState(uk);
    
    useEffect(() => {
        const getCntry = async () => {
            const data = await fetch("https://ipapi.co/json/");
            const ipDetails = await data.json();
            setCall(ipDetails.country_calling_code);
            setValues(prev =>({...prev, callingCode:ipDetails.country_calling_code}))
            
            switch(ipDetails.country_code){
                case "IN":setTop(india);break;
                case "USA":setTop(usa);break;
                default: setTop(uk);
            }
        }

        getCntry();
    }, [setCall, setValues]);

    const handleSelect = (val, cc) =>{
        switch(cc){
            case "IN":setCall("+91"); setValues(prev =>({...prev, callingCode:"+91"})); break;
            case "USA":setCall("+01"); setValues(prev =>({...prev, callingCode:"+01"})); break;
            default: setCall("+44"); setValues(prev =>({...prev, callingCode:"+44"}));
        }

        setTop(val);
        setOpen(false);
    }

    return (
        <div className="countryDD">
            <div className="front" onClick={() => setOpen(true)}>
                <img src={top} alt="top" />
                <img src={down} alt="down" />
            </div>

            {isOpen && (
                <div className="drop">
                    <div><img src={india} alt="india" onClick={() => handleSelect(india, "IN")} /></div>

                    <div><img src={usa} alt="usa" onClick={() => handleSelect(usa, "USA")} /></div>

                    <div style={{borderRadius: "0 0 50px 50px"}}><img src={uk} alt="uk" onClick={() => handleSelect(uk, "UK")} /></div>
                </div>
            )}
        </div>
    );
}

function AddContact({func}){
    const [callCode, setCallCode] = useState("+44");
    const [tickColor, setTick] = useState("transparent");
    const {sessEmail} = useContext(SessionContext);

    const tick = () =>{
        if (tickColor==="transparent"){
            setTick("#475569");
        }
        else{
            setTick("transparent");
        }
    }

    const [formValues, setValues] = useState({
        firstName:"",
        lastName:"",
        callingCode:"",
        phoneNumber:"",
        businessEmail:"",
        country:"",
        city:""
    });

    const [warnings, setWarnings] = useState({
        warn1:false,
        warn2:false,
        warn3:false,
        warn4:false,
        warn5:false,
        warn6:false,
        b1:"#CBD5E1",
        b2:"#CBD5E1",
        b3:"#CBD5E1",
        b4:"#CBD5E1",
        b5:"#CBD5E1",
        b6:"#CBD5E1",
    })

    const handleSubmit = (e) =>{
        e.preventDefault();
        let valid = true;

        if (!formValues.firstName.trim()){setWarnings(prev =>({...prev, warn1:true, b1:"red"})); valid=false}
        else{setWarnings(prev =>({...prev, warn1:false, b1:"#CBD5E1"}))}

        if (!formValues.phoneNumber.trim() || formValues.phoneNumber.length!==10){setWarnings(prev =>({...prev, warn2:true, b2:"red"})); valid=false}
        else{setWarnings(prev =>({...prev, warn2:false, b2:"#CBD5E1"}))}

        if (!formValues.country.trim()){setWarnings(prev =>({...prev, warn3:true, b3:"red"})); valid=false}
        else{setWarnings(prev =>({...prev, warn3:false, b3:"#CBD5E1"}))}

        if (!formValues.lastName.trim()){setWarnings(prev =>({...prev, warn4:true, b4:"red"})); valid=false}
        else{setWarnings(prev =>({...prev, warn4:false, b4:"#CBD5E1"}))}

        if (!formValues.businessEmail.trim()){setWarnings(prev =>({...prev, warn5:true, b5:"red"})); valid=false}
        else{setWarnings(prev =>({...prev, warn5:false, b5:"#CBD5E1"}))}

        if (!formValues.city.trim()){setWarnings(prev =>({...prev, warn6:true, b6:"red"})); valid=false}
        else{setWarnings(prev =>({...prev, warn6:false, b6:"#CBD5E1"}))}

        if (valid){
            fetch(`${BASE_URL}/v1/contacts`, {
                method:'POST',
                headers:{'Content-Type':'application/json'},
                credentials: 'include',
                body:JSON.stringify({...formValues})
            })
            .then(res =>res.json())
            .then(data => {
                if (data.message==='Contact added successfully'){
                    toast.success('Contact Added');
                    setTimeout(() =>{
                        window.location="/contacts";
                    }, 1000);
                    // setValues({
                    //     firstName:"",
                    //     lastName:"",
                    //     phoneNumber:"",
                    //     businessEmail:"",
                    //     country:"",
                    //     city:""
                    // });
                    // setTick("transparent");
                }
                else{
                    console.log(data);
                }
            })
            .catch(err =>console.log(err));
        }
    };
    
    return (
        <div className="addcontact">
            <img src={back} alt="back" className="back" onClick={() => func(false)}/>
            <p className="heading">Add Contact</p>
            <p className="desc">The "Add Contact" feature lets you quickly add new numbers to your contact list,<br></br>simplifying future interactions and communication.</p>

            <form autoComplete="off" name="contacts" onSubmit={(e) =>handleSubmit(e)}>
                <div className="inputs">
                    <div className="inpleft">

                        <p className="label"> First Name</p>
                        <div className="inpDiv1" style={{borderColor:warnings.b1}}>
                            <img src={acc} alt="account" />
                            <input 
                                type="text" 
                                name="First_Name" 
                                placeholder="e.g John"
                                value={formValues.firstName}
                                onChange={(e) =>setValues(prev => ({...prev, firstName:e.target.value}))}
                            />
                        </div>
                        {warnings.warn1 && (<p className="error">This field cannot be empty</p>)}

                        <p className="label">Phone Number</p>
                        <div className="inpDiv2" style={{borderColor:warnings.b2}}>
                            <CountryDD setCall={setCallCode} setValues={setValues} />
                            <span></span>
                            <p className="cnum">{callCode}</p>
                            <input 
                                type="number" 
                                alt="number" 
                                style={{width:"12.386vw"}} 
                                value={formValues.phoneNumber}
                                onChange={(e) =>setValues(prev =>({...prev, phoneNumber:e.target.value}))}
                            />
                        </div>
                        {warnings.warn2 && (<p className="error">Invalid phone number</p>)}

                        <p className="label">Country</p>
                        <div className="inpDiv1" style={{borderColor:warnings.b3}}>
                            <input 
                                type="text" 
                                name="Country" 
                                placeholder="e.g United Kingdom" 
                                style={{marginLeft:"0"}} 
                                value={formValues.country}
                                onChange={(e) =>setValues(prev =>({...prev, country:e.target.value}))}
                            />
                        </div>
                        {warnings.warn3 && (<p className="error">This field cannot be empty</p>)}
                    </div>

                    <div className="inpright">

                        <p className="label">Last Name</p>
                        <div className="inpDiv1" style={{borderColor:warnings.b4}}>
                            <img src={acc} alt="account" />
                            <input 
                                type="text" 
                                name="Last_Name" 
                                placeholder="e.g Doe" 
                                value={formValues.lastName}
                                onChange={(e) =>setValues(prev=>({...prev, lastName:e.target.value}))}
                            />
                        </div>
                        {warnings.warn4 && (<p className="error">This field cannot be empty</p>)}

                        <p className="label">Business Email</p>
                        <div className="inpDiv1" style={{borderColor:warnings.b5}}>
                            <img src={mail} alt="mail" />
                            <input 
                                type="email" 
                                name="Email" 
                                placeholder="e.g johndoe@example.com" 
                                value={formValues.businessEmail}
                                onChange={(e) =>setValues(prev =>({...prev, businessEmail:e.target.value}))}
                            />
                        </div>
                        {warnings.warn5 && (<p className="error">This field cannot be empty</p>)}

                        <p className="label">City</p>
                        <div className="inpDiv1" style={{borderColor:warnings.b6}}>
                            <input 
                                type="text" 
                                name="City" 
                                placeholder="e.g London" 
                                style={{marginLeft:"0"}} 
                                value={formValues.city}
                                onChange={(e) =>setValues(prev =>({...prev, city:e.target.value}))}
                            />
                        </div>
                        {warnings.warn6 && (<p className="error">This field cannot be empty</p>)}
                    </div>
                </div>
                <div className="priv">
                    <span onClick={tick}><div style={{backgroundColor:tickColor}}></div></span>
                    <p>By clicking this you give Reybot team to contact you. You accept us<br></br>to store your data as per our privacy policy in compliance with GDPR.</p>
                </div>

                {tickColor==="transparent" && (
                    <button type="submit" style={{cursor:"default", opacity:"0.5", pointerEvents:"none"}}>Save</button>
                )}
                {tickColor!=="transparent" && (
                    <button type="submit">Save</button>
                )}
            </form>
        </div>
    );
}

export default AddContact;