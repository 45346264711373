import React from 'react';
import { LoginForm } from "../Form/LoginForm";
import { SignUpForm } from "../Form/SignUpForm";

function Template({ title, formType, image }) {
  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Left side - Full-screen image */}
      <div className="hidden lg:flex lg:w-1/2 bg-[#001a2c] text-white relative overflow-hidden">
        <img
          src={image}
          alt="Reybot"
          className="absolute inset-0 object-cover w-full h-full"
        />
      </div>

      {/* Right side - Form */}
      <div className="w-full lg:w-1/2 p-8 flex flex-col justify-center items-center bg-white">
        <div className="w-full max-w-md">
          <div className="flex justify-between items-center mb-8">
            <div className="bg-black text-green-400 px-3 py-1 rounded-md font-bold">
              REYBOT
            </div>
          </div>
          <h1 className="text-3xl font-semibold mb-2">{title}</h1>
          <p className="mb-6 text-gray-600">
            Welcome to <span className="text-green-500 font-semibold">REYBOT</span>
          </p>
          {/* Conditionally render the form based on formType */}
          {formType === "signup" ? <SignUpForm /> : <LoginForm />}

          <p className="text-center mt-4 text-xs text-gray-500">
            You accept us to store your data as per our privacy policy in compliance with GDPR
          </p>
        </div>
      </div>
    </div>
  );
}

export default Template;