import React, { useState, useEffect, useRef, useContext } from "react";
import "./Model.css";
import PhoneImage from "../../assests/Phone — FixedHeight.png";
import { Plus, Trash2 } from "lucide-react";
import axios from "axios";
import { BASE_URL } from "../../utils/endPoint/baseUrl";
// import SessionContext from "../../context/SessionContext";

import { 
  getMetaStorageValues
} from '../../context/getAccessTokenData';

export const Modal = ({ closeModal, onSubmit, defaultValue }) => {
  const formatDateToMMDDYYYY = (date) => {
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const [variables, setVariables] = useState([]);
  const [variableCount, setVariableCount] = useState(0);
  const textAreaRef = useRef(null);
  const date = new Date();
  const formattedDate = formatDateToMMDDYYYY(date);

  const [formState, setFormState] = useState(
    defaultValue || {
      template: "",
      category: "",
      status: "",
      Language: "English(UK)",
      Text: "Text",
      last: formattedDate,
      interactiveButtons: "None",
      callToAction: {
        actionType: "Phone",
        buttonName: "",
        countryCode: "+44",
      },
      quickReplies: ["", ""],
      body: "",
      templateText: "",
    }
  );
  const [errors, setErrors] = useState("");
  const [mediaFile, setMediaFile] = useState(null);
  const [languages, setLanguages] = useState([
    "English(UK)",
    "Arabic",
    "Hindi",
  ]);

  // const { phoneId, accessToken } = useContext(SessionContext);

  const { 
    accessToken,
    whatsappId 
  } = getMetaStorageValues();


  const handleAddVariable = () => {
    const variableText = `{{${variables.length + 1}}}`;
    // const variableName = prompt("Enter variable name:");
    // if (variableName) {
    setVariables([...variables, variableText]);

    const cursorPosition = textAreaRef.current.selectionStart;
    const currentText = formState.body;
    const newText =
      currentText.slice(0, cursorPosition) +
      variableText +
      currentText.slice(cursorPosition);
    setFormState({ ...formState, body: newText });
    // }
  };

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const languageSet = new Set();

        response.data.forEach((country) => {
          if (country.languages) {
            Object.values(country.languages).forEach((language) => {
              languageSet.add(language);
            });
          }
        });
        setLanguages([...languageSet]);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);
  const handleFileUpload = (e) => {
    setMediaFile(e.target.files[0]);
  };

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const createMetaPayload = () => {
    const {
      template,
      category,
      body,
      templateText,
      callToAction,
      quickReplies,
      interactiveButtons,
      Text,
    } = formState;

    let payload = {
      name: template.toLowerCase().replace(/\s+/g, '_'),
      language: "en",
      category: category.toUpperCase(),
      components: [
        {
          type: "HEADER",
          format: "TEXT",
          text: Text
        },
        {
          type: "BODY",
          text: body || templateText,
          example: {
            body_text: [variables]
          }
        },
        {
          type: "FOOTER",
          text: "Reply with STOP to unsubscribe"
        }
      ]
    };

    if (category === "AUTHENTICATION") {
      payload.components = [
        {
          type: "BODY",
          text: body || templateText,
          example: {
            body_text: [variables],
          },
        },
      ];
    }

    if (interactiveButtons === "Quick replies") {
      payload.components.push({
        type: "BUTTONS",
        buttons: quickReplies.map((reply) => ({
          type: "QUICK_REPLY",
          text: reply,
        })),
      });
    } else if (interactiveButtons === "Call to action") {
      payload.components.push({
        type: "BUTTONS",
        buttons: [
          {
            type: "PHONE_NUMBER",
            text: callToAction.phoneButtonName || "Call",
            phone_number: callToAction.countryCode + callToAction.phoneNumber,
          },
          {
            type: "URL",
            text: callToAction.urlButtonName || "Visit Us",
            url: callToAction.urlLink,
          },
        ],
      });
    }

    return payload;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      template,
      category,
      title,
      body,
      templateText,
      callToAction,
      quickReplies,
      interactiveButtons,
    } = formState;

    const payload = createMetaPayload();

    try {
      const facebookResponse = await axios.post(
        `https://graph.facebook.com/v20.0/${whatsappId}/message_templates`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(
        "Facebook template created successfully:",
        facebookResponse.data
      );

      const formData = new FormData();
      formData.append("template", template);
      formData.append("category", category);
      formData.append("Language", "en");
      formData.append("title", title);
      formData.append("body", body || "");
      formData.append("templateText", templateText || "");
      formData.append("interactiveButtons", interactiveButtons);
      formData.append("facebookTemplateId", 2);
    
      if (interactiveButtons.toLowerCase() === 'call to action') {
        formData.append("callToAction", callToAction);
      }

      if (interactiveButtons.toLowerCase() === "quick replies") {
        quickReplies.forEach((reply) =>
          formData.append("quickReplies[]", reply)
        );
      }

      const serverResponse = await axios.post(
        `${BASE_URL}/templates`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true
        }
      );

      if (serverResponse.status === 201) {
        console.log("Template created successfully");
        closeModal();
      } else {
        console.error("Unexpected response status:", serverResponse.status);
        setErrors("An error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error creating template:", error);
      setErrors("An error occurred while creating the template.");
    }
    onSubmit(formState);
  };

  const handleCallToActionChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      callToAction: {
        ...prevState.callToAction,
        [name]: value,
      },
    }));
  };

  const handleQuickReplyChange = (index, value) => {
    setFormState((prevState) => {
      const newQuickReplies = [...prevState.quickReplies];
      newQuickReplies[index] = value;
      return { ...prevState, quickReplies: newQuickReplies };
    });
  };

  const addQuickReply = () => {
    setFormState((prevState) => ({
      ...prevState,
      quickReplies: [...prevState.quickReplies, ""],
    }));
  };

  const deleteQuickReply = (index) => {
    setFormState((prevState) => {
      const newQuickReplies = prevState.quickReplies.filter(
        (_, i) => i !== index
      );
      return { ...prevState, quickReplies: newQuickReplies };
    });
  };

  return (
    <div
      className="modal-container"
      onClick={(e) => {
        if (e.target.className === "modal-container") {
          closeModal();
        }
      }}
    >
      <div className="h-[90vh] w-[60vw] relative inset-0 -z-10 items-center px-5 pt-4 pb-12 left-36 bg-gray-100 rounded-xl overflow-hidden py-4">
        <div className="w-full h-12 text-lg font-semibold flex items-center px-[2%] border-gray-500 border-b-2">
          <h2>Create Template Message</h2>
        </div>

        <div className="w-full h-full rounded-xl overflow-hidden flex justify-center items-center mt-4">
          <div className="w-[70%] h-[100%] rounded-xl overflow-y-scroll">
            <div className="h-[60vh] w-[80%]">
              <form onSubmit={handleSubmit} className="mb-8">
                <div className="form-group">
                  <label htmlFor="template">Template Name</label>
                  <input
                    className="w-[50%]"
                    name="template"
                    onChange={handleChange}
                    value={formState.template}
                    required
                  />
                </div>

                <div className="form-group">
                  <div className="flex gap-5">
                    <label htmlFor="category">Category</label>
                    <select
                      name="category"
                      onChange={handleChange}
                      value={formState.category}
                      className="w-[23%]"
                      required
                    >
                      <option value="Utility">Utility</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Authentication">Authentication</option>
                    </select>

                    <label htmlFor="Language">Language</label>
                    <select
                      name="Language"
                      onChange={handleChange}
                      value={formState.Language}
                      className="w-[23%]"
                      required
                    >
                      {languages.map((lang) => (
                        <option key={lang} value={lang}>
                          {lang}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Conditionally hide components if category is "Authentication" */}
                {formState.category !== "Authentication" && (
                  <>
                    <div className="form-group flex justify-center gap-2">
                      <label htmlFor="Text">Template Type</label>
                      <select
                        name="Text"
                        value={formState.Text}
                        className="w-[23%]"
                        onChange={handleChange}
                        required
                      >
                        <option value="Text">Text</option>
                        <option value="Media">Media</option>
                        <option value="Video">Video</option>
                      </select>
                      {formState.Text === "Text" && (
                        <textarea
                          className="w-[80%] h-[40px] resize-none"
                          name="templateText"
                          placeholder="Type something brand related"
                          value={formState.templateText}
                          onChange={handleChange}
                        />
                      )}
                      {(formState.Text === "Media" ||
                        formState.Text === "Video") && (
                        <>
                          <input
                            type="file"
                            className="w-[80%]"
                            onChange={handleFileUpload}
                          />
                        </>
                      )}
                    </div>

                    <div className="my-4">
                      <label className="block mb-1">Interactive Buttons</label>
                      <div className="flex space-x-4">
                        {["None", "Call to action", "Quick replies"].map(
                          (option) => (
                            <label key={option} className="flex items-center">
                              <input
                                type="radio"
                                name="interactiveButtons"
                                value={option}
                                checked={
                                  formState.interactiveButtons === option
                                }
                                onChange={handleChange}
                                className="mr-2"
                              />
                              {option}
                            </label>
                          )
                        )}
                      </div>
                    </div>

                    {formState.interactiveButtons === "Call to action" && (
                      <div className="form-group">
                        <label>Call to Action Buttons</label>
                        <div className="flex flex-col items-center gap-2">
                          <div className="flex flex-col">
                            <label>Phone Button</label>
                            <div className="flex gap-2 items-center">
                              <input
                                className="w-[20%]"
                                type="text"
                                name="phoneButtonName"
                                placeholder="Phone Button Name"
                                value={
                                  formState.callToAction.phoneButtonName || ""
                                }
                                onChange={handleCallToActionChange}
                              />
                              <select
                                className="w-[15%]"
                                name="countryCode"
                                value={
                                  formState.callToAction.countryCode || "+44"
                                }
                                onChange={handleCallToActionChange}
                              >
                                <option value="+1">United States (+1)</option>
                                <option value="+44">
                                  United Kingdom (+44)
                                </option>
                                <option value="+91">India (+91)</option>
                                <option value="+61">Australia (+61)</option>
                                <option value="+81">Japan (+81)</option>
                                <option value="+49">Germany (+49)</option>
                                <option value="+33">France (+33)</option>
                                <option value="+86">China (+86)</option>
                                <option value="+55">Brazil (+55)</option>
                                <option value="+27">South Africa (+27)</option>
                              </select>
                              <input
                                className="w-[30%]"
                                type="text"
                                name="phoneNumber"
                                placeholder="Phone Number"
                                value={formState.callToAction.phoneNumber || ""}
                                onChange={handleCallToActionChange}
                              />
                            </div>
                          </div>

                          <div className="-ml-20 flex flex-col">
                            <label>URL Button</label>
                            <div className="flex gap-2 items-center">
                              <input
                                className="w-[20%]"
                                type="text"
                                name="urlButtonName"
                                placeholder="URL Button Name"
                                value={
                                  formState.callToAction.urlButtonName || ""
                                }
                                onChange={handleCallToActionChange}
                              />
                              <input
                                className="w-[50%]"
                                type="text"
                                name="urlLink"
                                placeholder="URL Link"
                                value={formState.callToAction.urlLink || ""}
                                onChange={handleCallToActionChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {formState.interactiveButtons === "Quick replies" && (
                      <div className="form-group">
                        <label>Quick Replies</label>
                        {formState.quickReplies.map((reply, index) => (
                          <div
                            key={index}
                            className="flex items-center gap-2 mb-2"
                          >
                            <input
                              className="w-[90%]"
                              type="text"
                              value={reply}
                              onChange={(e) =>
                                handleQuickReplyChange(index, e.target.value)
                              }
                            />
                            <Trash2
                              className="cursor-pointer"
                              onClick={() => deleteQuickReply(index)}
                            />
                          </div>
                        ))}
                        <div className="w-[30%]">
                          <Plus
                            className="cursor-pointer"
                            onClick={addQuickReply}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="form-group my-4">
                  <label htmlFor="body">Template Body</label>
                  <div className="flex flex-col gap-2 items-start">
                    <textarea
                      className="w-[380px] h-[80px] resize-none"
                      placeholder="write down at least 25 words..."
                      name="body"
                      value={formState.body}
                      onChange={handleChange}
                      ref={textAreaRef}
                      required
                    />
                    <button
                      type="button"
                      className="text-blue-500"
                      onClick={handleAddVariable}
                    >
                      Add Variable
                    </button>
                  </div>
                </div>
                <button
                  type="submit"
                  className="px-6 py-2 bg-blue-500 text-white rounded mt-4 hover:bg-blue-600 transition-colors duration-200"
                >
                  Create Template
                </button>

                {errors && <div className="text-red-500 mt-4">{errors}</div>}
              </form>
            </div>
          </div>

          <div className="w-[30%] h-[100%] bg-white flex justify-center items-end rounded-lg overflow-hidden">
            <img src={PhoneImage} alt="Phone" />
          </div>
        </div>
      </div>
    </div>
  );
};
