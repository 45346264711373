import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function OpenRoute({ children }) {
  const { token } = useSelector((state) => state.auth);


  if (token !== null && window.location.pathname === "/login") {

    return <Navigate to="/dashboard" />;
  }

  return children; 
}

export default OpenRoute;
